/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SHOW_CONTENT,
    HIDE_CONTENT,
	SHOW_KRPANO_CONTENT,
    HIDE_KRPANO_CONTENT
} from "../actions/content/types";


/*
 * ---------------------------------------------------------------------
 * initial popovers state
 * ---------------------------------------------------------------------
 */
const initialState = {
    isOpen: false,
    type : "closed",
    url : null,
    size : "lg",
	isKrpanoContentOpen: false
};
/*
 * ---------------------------------------------------------------------
 * popovers reducer
 * ---------------------------------------------------------------------
 */
const popovers = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CONTENT:
            return Object.assign({}, state, {
                isOpen: true,
                type: action.payload.type,
                url: action.payload.url,
                size: action.payload.size,
            });

        case HIDE_CONTENT:
            return Object.assign({}, state, {
				isOpen: false,
			    type : "closed",
			    url : null,
			    size : "lg",
            });

		case SHOW_KRPANO_CONTENT:
	        return Object.assign({}, state, {
	            isKrpanoContentOpen: true,
	        });

	    case HIDE_KRPANO_CONTENT:
	        return Object.assign({}, state, {
	            isKrpanoContentOpen: false,
	        });

        default:
             return state;
    }
};

export default popovers;
