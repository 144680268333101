/**
* react-router-dom v6 no longer supports withRouter
* so this is a recreation
* @see https://reactrouter.com/docs/en/v6/getting-started/faq#what-happened-to-withrouter-i-need-it
*/

import {
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}
/*
* ---------------------------------------------------------------------
* Export
* ---------------------------------------------------------------------
*/
export default withRouter;
