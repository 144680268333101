/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
	OPEN_TOP_ACHIEVEMENTS,
    CLOSE_TOP_ACHIEVEMENTS,
    OPEN_SIDE_ACHIEVEMENTS,
    CLOSE_SIDE_ACHIEVEMENTS,
    SET_ACTIVE_GAME
} from "src/shared_modules/veu-visitors-core/actions/achievements/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
	isTopAchievementsOpen : false,
    isSideAchievementsOpen : false,
    activeGameID : null
};



/*
 * ---------------------------------------------------------------------
 *  reducer
 * ---------------------------------------------------------------------
 */
const moderator = (state = initialState, action) => {
    switch( action.type ) {
		case OPEN_TOP_ACHIEVEMENTS:
            return Object.assign({}, state, {
                isTopAchievementsOpen: true
            });
        case CLOSE_TOP_ACHIEVEMENTS:
            return Object.assign({}, state, {
                isTopAchievementsOpen: false
            });
        case OPEN_SIDE_ACHIEVEMENTS:
            return Object.assign({}, state, {
                isSideAchievementsOpen: true
            });
        case CLOSE_SIDE_ACHIEVEMENTS:
            return Object.assign({}, state, {
                isSideAchievementsOpen: false
            });
        case SET_ACTIVE_GAME:
        	return Object.assign({}, state, {
                activeGameID: action.payload.activeGameID
            });
        default:
            return state;
    }
};

export default moderator;
