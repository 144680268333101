import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
//don't need to specify localhost url in axios http address
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AppLoader from './AppLoader';
import { Provider } from 'react-redux';
import store from './store';
import { setupAxiosGlobals } from "./config/axios";
import * as serviceWorker from './serviceWorker';
import ErrorPage from "src/shared_modules/veu-visitors-core/components/error/page";

// this is the best place to init axios! right before anything else...
setupAxiosGlobals();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
			<Routes>
                <Route path="/" element={<Navigate to="/fallback" />} />
                <Route path="/:event/*" element={<AppLoader />} />
				<Route path="/:event" element={<AppLoader />} />
				<Route path="*" element={<ErrorPage status="404" msg="Not Found" />} />
            </Routes>
        </Provider>
    </BrowserRouter>
);

// default react service worker
serviceWorker.unregister();
