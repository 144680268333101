/*
 * ---------------------------------------------------------------------
 * Content Action Types
 * ---------------------------------------------------------------------
 */
export const SHOW_CONTENT = "SHOW_CONTENT";

export const HIDE_CONTENT = "HIDE_CONTENT";

export const SHOW_KRPANO_CONTENT = "SHOW_KRPANO_CONTENT";
export const HIDE_KRPANO_CONTENT = "HIDE_KRPANO_CONTENT";
