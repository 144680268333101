/*
 * ---------------------------------------------------------------------
 * System Action Types
 * ---------------------------------------------------------------------
 */
export const OPEN_TOP_ACHIEVEMENTS = "OPEN_TOP_ACHIEVEMENTS";
export const CLOSE_TOP_ACHIEVEMENTS = "CLOSE_TOP_ACHIEVEMENTS";
export const OPEN_SIDE_ACHIEVEMENTS = "OPEN_SIDE_ACHIEVEMENTS";
export const CLOSE_SIDE_ACHIEVEMENTS = "CLOSE_SIDE_ACHIEVEMENTS";

export const SET_ACTIVE_GAME = "SET_ACTIVE_GAME";
