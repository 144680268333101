
/*
 * ---------------------------------------------------------------------
 * global constants
 * ---------------------------------------------------------------------
 */
export const SYSTEM_STATE = {
    INIT: "initialize",
    SETUP: "setup",
    READY: "ready",
    ERROR: "error",
    MAINTENANCE: "maintenance"
};

export const DOCTITLE_PREFIX = "Schaeffler SVEP";

export const VERSION = process.env.REACT_APP_VERSION;
export const NAME = "SVEP";
export const MODULE = "frontend-visitors";
export const COPYRIGHT = "(c)2020";

// TODO move uuid to config and send it as header
export const UUID = "3c62952f9-747f-484d-8fef-63dbef15d705";

// items for legal notices; CUSTOM
export const FOOTER_CONTENT = {
    SEVENM: true,
    LEGAL_NOTICE: true,
    PRIVACY_POLICY: true,
    CONDITION_OF_USE: true,
    COOKIE_POLICY: true,
}

// system content linking either static or global except seven m
export const SYSTEM_CONTENT = {
    SEVENM: { show: true, type: "extern", url: "https://seven-m.de" },
    //HELP: { show: true, type: "global", id: "global-page-help" },
    HELP: { show: true, type: "static", url: "help.html" },
    //TUTORIAL: { show: true, type: "global", id: "global-page-tutorial" },
    TUTORIAL:{ show: true, type: "static", url: "tutorial.html" },
    //LEGAL_NOTICE: { show: true, type: "global", id: "global-page-legal-notice" },
    LEGAL_NOTICE: { show: true, type: "static", url: "legal-notice.html" },
    //PRIVACY_POLICY: { show: true, type: "global", id: "global-page-privacy-policy" },
    PRIVACY_POLICY: { show: true, type: "static", url: "privacy-policy.html" },
    //CONDITION_OF_USE: { show: true, type: "global", id: "global-page-help" },
    CONDITION_OF_USE: { show: true, type: "static", url: "therms-of-use.html" },
    //COOKIE_POLICY: { show: true, type: "global", id: "global-page-cookies" },
    COOKIE_POLICY:{ show: true, type: "static", url: "cookies.html" },
}
