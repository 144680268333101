/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_EXHIBIT_LIST,
    SET_ACTIVE_EXHIBIT,

} from "../actions/stageview/types";

/*
 * ---------------------------------------------------------------------
 * initial event state
 * ---------------------------------------------------------------------
 */
// Till now the event is null maybe in future we change this behaviour
const initialState = {
    // event core features mandatory
    active: null,
    list: [],
    openExhibit: null,
};


/*
 * ---------------------------------------------------------------------
 * stream reducer
 * ---------------------------------------------------------------------
 */
const exhibits = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXHIBIT_LIST:
            return Object.assign({}, state, {
                list: action.payload,
            });
        case SET_ACTIVE_EXHIBIT:
            return Object.assign({}, state, {
                openExhibit: action.payload,
            });

        default:
            return state;
    }
};

export default exhibits;
