/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SHOW_CONTENT,
    HIDE_CONTENT,
	SHOW_KRPANO_CONTENT,
	HIDE_KRPANO_CONTENT,
} from "./types";

import {
    hideAllPopovers
} from "src/shared_modules/veu-visitors-core/actions/popovers";

import { hidePageGlobal } from "src/shared_modules/veu-visitors-core/actions/pages";

/*
 * ---------------------------------------------------------------------
 * Public Content Actions
 * ---------------------------------------------------------------------
 */
export const showContent = ( type, url, size ) => ({
    type: SHOW_CONTENT,
    payload: {
        type: type,
        url: url,
        size: size,
    }
});

export const hideContent = () => ({
    type: HIDE_CONTENT,
    payload: null
});

export const showKrpanoContent = () => ({
    type: SHOW_KRPANO_CONTENT,
    payload: null
});

export const hideKrpanoContent = () => ({
    type: HIDE_KRPANO_CONTENT,
    payload: null
});


/**
 * [showStaticContent description]
 *
 * for opening content from public/html/[lang]
 *
 * @param  {String} url              html path staring after public/html/[lang]
 * @param  {String} [size="lg"]      [description]
 * @param  {bool}   [autohide=true]
 * @param  {func}                    possible custom auto close redux action
 * @return {[type]}                  [description]
 */
export const showStaticContent = ( url, size="lg" , autoclose=true, customAutoClose = null) => {

    return async ( dispatch, getState ) => {
        if( autoclose ) {
            dispatch( hideAllPopovers());
            dispatch( hidePageGlobal() );
            if( customAutoClose )
                dispatch( customAutoClose() );
        }
        // TODO Close chat close, all popovers,
        dispatch( showContent("static",url, size));
    }
};

/**
 * showExternalContent
 *
 * for showing any external website
 *
 * @param  {object} url          {  "de-DE": "url-de", "en-GB": "url-en" ... }
 * @param  {String} [size="lg"] [description]
 * @return {[type]}             [description]
 */
export const showExternalContent = ( url, size="lg" ) => {
    return async ( dispatch, getState ) => {
        dispatch( hideAllPopovers());
        dispatch( hidePageGlobal() );

        // TODO Close chat close, all popovers,
        dispatch( showContent("external",url, size));
    }
};
