/**
 * ---------------------------------------------------------------------
 * VEU frontend-visitors loader
 * ---------------------------------------------------------------------
 * @author seven-m.de
 * @copyright SEVEN M 2020
 *
 * This class pre-loads every thing needed befor the app can start.
 * Loading Chain:
 *  - Check event
 *  - load i18n
 *  - check session for annonymos and loggin recovery
 *  - load start location
 *
 * All calls without event parameter always fail!
 */
/*
 * ---------------------------------------------------------------------
 * import modules
 * ---------------------------------------------------------------------
 */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from "src/shared_modules/react-components/pure/with-router";

import { ThemeProvider } from '@material-ui/core/styles';
import { licenseeTheme } from './Themes.js';

import { SnackbarProvider } from 'notistack';

import {
    init as initi18n,
    changeLanguage,
    currentLanguage,
    getLanguageByLocale,
} from 'src/shared_modules/i18n';

import LoadingOverlay from 'src/shared_modules/veu-visitors-core/components/loader';
import ErrorPage from 'src/shared_modules/veu-visitors-core/components/error/page';

import {
    fetchCDN,
    fetchStartloc,
    setAppState,
    setLoading,
    setCurrentLanguage,
} from 'src/shared_modules/veu-visitors-core/actions/system';

import {
    fetchEvent,
} from 'src/shared_modules/veu-visitors-core/actions/event';

import {
    recoverSession,
    doAnonym,
    doRDPWechat,
    doRDPKomdat,
} from 'src/shared_modules/veu-visitors-core/actions/user';

import {
    doSSOByPlazzLink,
} from 'src/shared_modules/veu-visitors-plazz/actions/user';

import {
    fetchMeaConfig,
} from 'src/shared_modules/veu-visitors-plazz/actions/mea';

import './App.css';

import {
    NAME,
    MODULE,
    VERSION,
    UUID
} from "./constants";

import {
    VEU_CORE_VERSION,
    VEU_NAME,
} from "src/shared_modules/veu-visitors-core/constants";

import {
    VEU_PLAZZ_VERSION,
    VEU_PLAZZ_NAME,
} from "src/shared_modules/veu-visitors-plazz/constants";

import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { getMatomoConfig } from './config/matomo';

const matomo = getMatomoConfig();

const mamotoInstance = createInstance({
    urlBase: matomo.baseUrl,
    siteId: matomo.siteId,
    linkTracking: true     // Important! must be enabled to get the query campaing params
});

/*
 * ---------------------------------------------------------------------
 * lazy import modules (Always import at last!)
 * ---------------------------------------------------------------------
 */
const App = React.lazy(() => import('./App'));

/*
 * ---------------------------------------------------------------------
 * AppLoader React Component
 * ---------------------------------------------------------------------
 */
class AppLoader extends Component {
    constructor() {
        super();
        // local internal component state
        this.state = {
        };
    }

    componentDidMount() {
        let ev = this.props.router.params.event;

        // APP Info
        console.info("%cLaunching App ...", 'color: blue');
        console.group("%c" + NAME + " " + MODULE, 'color: blue');
        console.log("NODE_ENV: " + process.env.NODE_ENV);
        console.log("DEPLOY_ENV: " + process.env.REACT_APP_DEPLOY_ENV);
        console.log("Version: " + VERSION);
        console.log("UUID: " + UUID);
        console.log("BaseUrl: " + window.location.origin);
        console.log("Event: " + ev);
        console.groupEnd();
        // Core Info
        console.group("%c " + VEU_NAME, 'color: blue');
        console.log("Version: " + VEU_CORE_VERSION);
        console.groupEnd();
        // VEU Plazz Info
        console.group("%c " + VEU_PLAZZ_NAME, 'color: blue');
        console.log("Version: " + VEU_PLAZZ_VERSION);
        console.groupEnd();

        // binding this - does nothing more !
        this.initialize = this.initialize.bind(this);
        this.initViaPlazzRedirect = this.initViaPlazzRedirect.bind(this);
        this.initRDPWechat = this.initRDPWechat.bind(this);
        this.initRDPKomdat = this.initRDPKomdat.bind(this);
        this.initCurrentLanguage = this.initCurrentLanguage.bind(this);

        // start init
        this.initialize();
    }

    componentDidUpdate(prevProps) {
        let { system } = this.props;

        if (system.error !== null && system.isLoading)
            this.props.setLoading(false);
    }

    async initViaPlazzRedirect(search) {
        const params = new URLSearchParams(search);
        // redirect from plazz
        let session = params.get("session");
        if (session !== "" && session !== null) {
            this.props.setAppState("sso-plazz");
            await this.props.doSSOByPlazzLink(session);
        }
    }

    async initRDPWechat(rdp, params) {
        //console.warn("Access via Redirect Provider " + rdp);
        if (rdp === "wechat") {
            let token = params.get("token");
            if (!token || token === "") {
                console.error("ERROR Redirect Provider " + rdp + " missing token!");
                return;
            }
            // wechat needs backend verifcation
            // and returns a wechat user if successfull
            await this.props.doRDPWechat(token);
        } else if (rdp === "komdat") {
            // see below initRDPKomdat
        } else {
            console.error("ERROR Unknown Redirect Provider " + rdp);
        }
    }

    async initRDPKomdat(rdp, params) {
        //console.warn("Access via Redirect Provider " + rdp);
        if (rdp === "wechat") {
            // see above initRDPWechat
        } else if (rdp === "komdat") {
            let leadId = params.get("id");
            if (!leadId || leadId === "") {
                console.error("ERROR Redirect Provider " + rdp + " missing id!");
                return;
            }
            // komdot request external system stores it to use data in userlike
            await this.props.doRDPKomdat(leadId);
        } else {
            console.error("ERROR Unknown Redirect Provider " + rdp);
        }
    }

    async initCurrentLanguage() {
        let curr = currentLanguage();

        // single languge events special case override current lang. And if current lang not included in event languages
        if (this.props.eventLanguages.length === 1 || !this.props.eventLanguages.includes(curr.locale)) {
            let singleLang = getLanguageByLocale(this.props.eventLanguages[0]);
            if (curr.locale !== singleLang.locale)
                curr = singleLang;
        }

        this.props.setCurrentLanguage(curr.locale);
        await changeLanguage(curr.code2);
    }

    async initialize() {
        let ev = this.props.router.params.event;
		//console.error("initialize ev: " + JSON.stringify(this.props.router));
        // phase 1 - fetch event
		if (ev) {
        	await this.props.fetchEvent(ev);
		}
		else {
			return;
		}

        // failsave if event was wrong
        if (this.props.event === null)
            return;

        // phase 2 - loading translations and init language settings for ui.
        this.props.setAppState("init-i18n");
        await initi18n();

        await this.initCurrentLanguage();

        // phase 3 - sso plazz or recover session or redirect provider
        let { pathname, search } = this.props.router.location;
        // look for rdp queries
        const params = new URLSearchParams(search);
        let rdp = params.get("rdp");
        // these are special cases because we are called by a external link
        // and this muss be done during the app loading phase.
        // because after internal app redirecting the query params are gone
        if( pathname.endsWith("/plazz") ) {
            if( search !== "" ) {
                await this.initViaPlazzRedirect(search);
            }
        } else if (rdp && rdp !== "" ) {
            // check here WeChat
            await this.initRDPWechat(rdp, params);
        }

		let isSessionAvailable = false;

        if (this.props.user._id === null) {
            // try to recover session form cookie
            this.props.setAppState("check-session");
            isSessionAvailable = await this.props.recoverSession();
        }

        // phase 4
        if (!isSessionAvailable) {
            await this.props.doAnonym();
        }
        // phase 5
        this.props.setAppState("init-page");

        if (rdp && rdp !== "") {
            // check here komdat because we need an user!
            await this.initRDPKomdat(rdp, params);
        }
        // right now this is mandatory!
        await this.props.fetchMeaConfig();

        await this.props.fetchCDN();
        await this.props.fetchStartloc();

        this.props.setAppState("ready");
        this.props.setLoading(false);
    }

    render() {
        let err = this.props.system.error;
        let state = this.props.system.appState;
        let loading = this.props.system.isLoading;

        // yes this is correct! otherwise suspense unmouts which is a major fail
        let startApp = (state === "ready" || state === "internal-error");

		if(err)
			console.error("Apploader - Error: " + err.statusText);

        return (<React.Fragment>
            <MatomoProvider value={mamotoInstance}>
                <ThemeProvider theme={licenseeTheme}>
                    {!loading && err && <ErrorPage status={err.status} msg={err.statusText} />}
                    {loading && <LoadingOverlay />}
                    {/* start the app if its ready and all init phases are done */}
                    {startApp && <Suspense fallback={<LoadingOverlay />}>
					 	<SnackbarProvider maxSnack={3} persist="true" anchorOrigin={{
							   vertical: 'top',
							   horizontal: 'right',
							 }}>
	                        <App />
						</SnackbarProvider>
                    </Suspense>}
                </ThemeProvider>
            </MatomoProvider>
        </React.Fragment>);

    }
}


/*
 * ---------------------------------------------------------------------
 * AppLoader prop Types
 * ---------------------------------------------------------------------
 */
AppLoader.propTypes = {
    system: PropTypes.object,
    event: PropTypes.object,
    user: PropTypes.object,
};
/*
 * ---------------------------------------------------------------------
 * UserSection Redux Container
 * ---------------------------------------------------------------------
 */
const mapStateToProps = (state, ownProps) => {
    return {
        system: state.system,
        event: state.event,
        user: state.user,
        eventLanguages: state.event.languages,
    };
};

const mapDispatchToProps = {
    // event
    fetchEvent,
    //system
    fetchCDN,
    fetchStartloc,
    setAppState,
    setLoading,
    setCurrentLanguage,
    // user
    recoverSession,
    doAnonym,
    doRDPWechat,
    doRDPKomdat,
    // plazz
    doSSOByPlazzLink,
    fetchMeaConfig,
};

/*
 * ---------------------------------------------------------------------
 * Export the redux container
 * ---------------------------------------------------------------------
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AppLoader));
