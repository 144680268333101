/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
import axios from "axios";

import {
    SET_USER,
    SET_PROFILE,
    CLEAR_USER,
    SET_USER_LOCATION,
    SET_PREREGISTERED,
    SET_KOMDAT_LEAD,
    SET_KOMDAT_USERLIKEWASOPENED,
    SET_MINIEVENTS_LOGGEDIN,
    ADD_ALREADY_ACCESSED_SCENE,
} from "./types";

import {
    setUserCount,
    setStartloc,
    redirectTo,
    setError,
    setScene,
    fetchStartloc,
} from "src/shared_modules/veu-visitors-core/actions/system";

import {
    presetLoginHint,
    hidePopover
} from "src/shared_modules/veu-visitors-core/actions/popovers"

import { currentLanguage } from "src/shared_modules/i18n";
import { getConfig } from "src/config/axios";

/*
 * ---------------------------------------------------------------------
 * internal Actions
 * ---------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------
 * Public Actions
 * ---------------------------------------------------------------------
 */

export const setUser = ( user ) => ({
    type: SET_USER,
    payload: {
        user : user
    }
});

export const setUserProfile = ( profile ) => ({
    type: SET_PROFILE,
    payload: profile
});

export const clearUser = () => ({
    type: CLEAR_USER
});

export const setUserLocation = ( location ) => ({
    type: SET_USER_LOCATION,
    payload: {
        location : location
    }
});

export const setPreregistered = (val) => ({
    type: SET_PREREGISTERED,
    payload: val
});

export const setKomdatLead = ( leadinfo ) => ({
    type: SET_KOMDAT_LEAD,
    payload: leadinfo
});
export const userlikeWasOpened = () => ({
    type: SET_KOMDAT_USERLIKEWASOPENED,
    payload: null
})

export const setMinieventsLoggedIn = (val) => ({
    type: SET_MINIEVENTS_LOGGEDIN,
    payload: val
});

export const addAlreadyAccessedScene = (sceneName) => ({
    type: ADD_ALREADY_ACCESSED_SCENE,
    payload: {
        sceneName : sceneName
    }
});
/*
 * ---------------------------------------------------------------------
 * async thunks
 * ---------------------------------------------------------------------
 */

/**
 * recoverSession async thunk dispatch
 *
 * is used if for browser url changes (by user) or f5 reloads to preserver the session from the cookie.

 * @return {[type]}        [description]
 */
export const recoverSession = () => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

		var isSessionAvailable = false;

        await axios.get( "/account/",
            {
                withCredentials: true,
                crossDomain: true,
                headers: headers
            }
        ).then(response => {
            //console.log('recoverSession response: ');
            //console.log( response.data );
            if( response.data.user !== null ) {
                //console.log('recoverSession: There is a user saved in the server session: ')
                dispatch(setUser(response.data.user));
				isSessionAvailable = true;
            } else {
                dispatch(clearUser());
				isSessionAvailable = false;
            }
        }).catch(error => {
            //console.error(error.response);
            dispatch(clearUser());
			isSessionAvailable = false;
        });

        return isSessionAvailable;
     };
}

/**
 * default login async thunk dispatch
 *
 * first trys a pure local login and then the backend trys to use plazz as default login provider
 *
 * @param  {[type]} username  [description]
 * @param  {[type]} password  [description]
 * @return {[type]}           [description]
 */
export const login = ( username, password ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        let data = {
            username: username,
            password: password,
            lang:  currentLanguage().code2
        }

        var error = false;

        await axios.post( "/account/login/",
            data,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200 && response.data.user) {

                dispatch(setUser(response.data.user));
                let redirect = ev;
                if( response.data.redirect ) {
                    redirect = redirect + response.data.redirect.path;
                }
                dispatch( fetchStartloc() );
                dispatch( redirectTo( redirect ) );
                // Force krpano update
                if( redirect.indexOf("location") > -1 )
                    dispatch( setScene(null) );
            } else {
                console.error("veu login failed - no user");
                error = response;
            }
        })
        .catch( (err) => {
            console.error("veu login failed");
            error = err.response;
        });

        return error;
    }
};

/**
 * default logout async thunk dispatch
 *
 * @return {[type]}        [description]
 */
export const logout = () => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let user = getState().user;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        let error = false;
        await axios.post("/account/logout/",
            user,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if (response.status === 200) {
                let redirect = "/";
                if( response.data.redirect )
                    redirect = ev + response.data.redirect.path;

                dispatch( hidePopover("Account") );
                dispatch( hidePopover("Privacy") );
                dispatch( hidePopover("Profile") );

                dispatch( setUser(response.data.user));
                dispatch( setStartloc( response.data.redirect ) )
                dispatch( redirectTo(  redirect ) );
                // Force krpano update
                if( redirect.indexOf("location") > -1 )
                    dispatch( setScene(null) );
            }
        })
        .catch( (err) => {
            dispatch(clearUser());
            console.error("veu logout failed");
            console.error( JSON.stringify(error,null,2));
            error = err.response;
        });

        return error;
    }
}

/**
 * register async thunk dispatch
 *
 *
 * @param  {[type]} fields
 *
 * @return {[type]}           [description]
 */
export const registerTest = ( fields ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;
        headers["VEU-LANG"] = currentLanguage().code2;

        var error = false;

        await axios.post( "/account/register/test/",
            fields,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200) {

            } else {
                //console.error("veu register failed " + JSON.stringify(response));
                error = response;
            }
        })
        .catch( (err) => {
            //console.error("veu register failed " + JSON.stringify(err));
            error = err.response.data.code;
        });

        return error;
    }
};

export const registerNow = ( fields ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;
        headers["VEU-LANG"] = currentLanguage().code2;

        var error = false;

        await axios.post( "/account/register/now/",
            fields,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200) {

            } else {
                //console.error("veu register failed " + JSON.stringify(response));
                error = response;
            }
        })
        .catch( (err) => {
            //console.error("veu register failed " + JSON.stringify(err));
            error = err.response.data.code;
        });

        return error;
    }
};

export const requestNewPassword = ( fields ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;
        headers["VEU-LANG"] = currentLanguage().code2;

        var error = false;

        await axios.post( "/account/newPassword/",
            fields,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200) {

            } else {
                //console.error("veu register failed " + JSON.stringify(response));
                error = response;
            }
        })
        .catch( (err) => {
            //console.error("veu register failed " + JSON.stringify(err));
            error = err.response.data.code;
        });

        return error;
    }
};

export const updateProfile = ( fields ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        var error = false;
        await axios.post( "/account/updateProfile/",
            fields,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200) {
                dispatch(setUser(response.data.user));
            } else {
                console.error("user update failed " + JSON.stringify(response));
                error = response;
            }
        })
        .catch( (err) => {
            console.error("veu update failed " + JSON.stringify(err));

            error = err.response.data.code;

            if (err.message === "Request failed with status code 413") {
              error = "imageTooLarge";
            }
        });

        return error;
    }
};

export const updateAccount = ( fields ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;
        var error = false;
        await axios.post( "/account/updateAccount/",
            fields,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200) {
                dispatch(setUser(response.data.user));
            } else {
                console.error("user update failed " + JSON.stringify(response));
                error = response;
            }
        })
        .catch( (err) => {
            console.error("veu update failed " + JSON.stringify(err));
            error = err.response.data.code;
        });

        return error;
    }
};

export const deleteAccount = ( fields ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;
        headers["VEU-LANG"] = currentLanguage().code2;
        var error = false;
        await axios.post( "/account/delete/",
            { password : fields.password },
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200) {
                let redirect = "/";
                if( response.data.redirect )
                    redirect = ev + response.data.redirect.path;

                dispatch( setUser(response.data.user));
                dispatch( setStartloc( response.data.redirect ) )
                dispatch( redirectTo(  redirect ) );
                // Force krpano update
                if( redirect.indexOf("location") > -1 )
                    dispatch( setScene(null) );

            } else {
                console.error("user delete failed " + JSON.stringify(response));
                error = response;
            }
        })
        .catch( (err) => {
            console.error("veu user delete failed " + JSON.stringify(err));
            error = err.response.data.code;
        });

        return error;
    }
};

/**
 * anonym login async thunk dispatch
 *
 * anonym login to create a session used by public events
 *
 * @return {[type]}           [description]
 */
export const doAnonym = ( ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        var error = false;

        await axios.post( "/account/anonym/",
            {
                // data not needed
            },
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200 && response.data.user) {

                dispatch(setUser(response.data.user));

                dispatch( fetchStartloc() );
                // Removing this fixed the deep link bug,
                // be careful maybe we have now some other side effects.
                //let redirect = ev;
                //if( response.data.redirect ) {
                //    redirect = redirect + response.data.redirect.path;
                //}
                //dispatch( redirectTo(  redirect ) );
            } else {
                console.error("veu anonym login failed - no user");
                error = response;
            }
        })
        .catch( (err) => {
            console.error("veu anonym login failed");
            error = err.response;
        });

        return error;
    }
};

export const doRDPWechat = ( token ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        var error = false;
        let data = {
            token : token
        }

        await axios.post( "/visitors/rdp/wechat/",
            data,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200 && response.data.user ) {

                dispatch(setUser(response.data.user));

                let redirect = ev;
                if( response.data.redirect ) {
                    redirect = redirect + response.data.redirect.path;
                }
                dispatch( fetchStartloc() );
                dispatch( redirectTo( redirect ) );
            } else {
                if( response.data.error ) {
                    console.error("veu rdp wechat login failed - " + response.data.error );
                    dispatch( redirectTo( response.data.redirect.path ) );
                    dispatch( presetLoginHint( response.data.error ) );
                }
                console.error("veu rdp wechat login failed - no user");
            }
        })
        .catch( (err) => {
            console.error("veu rdp wechat login failed");
            error = err.response;
        });

        return error;
    }
};

export const doRDPKomdat = ( leadId ) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let ev = getState().event.path;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        var error = false;

        await axios.get( "/visitors/rdp/komdat/lead/"+leadId ,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
        .then( (response) => {
            if( response.status === 200 && !response.data.Error ) {
                dispatch(setKomdatLead(response.data));
            }
        })
        .catch( (err) => {
            console.error("veu rdp komdat failed");
            error = err.response;
        });
        return error;
    }
};

export const updateScene = ( path, sceneId=null ) => {

    return async ( dispatch, getState ) => {
        //console.log("updateScene: " + path + "/" + sceneId);
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        let user = getState().user;
        if( user.type === null )
            return;

        dispatch( setError(null) );

        let done = await axios.post("/visitors/community/updateloc",{
            path : path,
            sceneId : sceneId,
        },{
            crossDomain: true,
            // options
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if( response.status === 200 ) {
                dispatch( setUserLocation(response.data.user.location) );
                dispatch( setUserCount(response.data.usercount) );
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            console.error( JSON.stringify(error,null,2));
        });
        return done;
    }
}

export const fetchIsPreregistered = () => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        let user = getState().user;
        if( user.type === null )
            return;

        dispatch( setError(null) );

		let isPreregistered = false;

        await axios.get("/visitors/preregister/is",{
            crossDomain: true,
            // options
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if( response.status === 200 ) {
                dispatch( setPreregistered(response.data.is) );
				isPreregistered = response.data.is;
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            console.error( JSON.stringify(error,null,2));
        });
        return isPreregistered;
    }
}

export const doPreregister = (fields) => {
    return async ( dispatch, getState ) => {
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;
        headers["VEU-LANG"] = currentLanguage().code2;

        let user = getState().user;
        if( user.type === null )
            return;

        dispatch( setError(null) );

        let data = {};
        if( fields != null ) {
            data["fields"] = fields;
        }

		let isPreregistered = false;

        await axios.post("/visitors/preregister/do",
            data,
            {
                crossDomain: true,
                // options
                headers: headers
            }
        )
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if( response.status === 200 ) {
                dispatch( setPreregistered(response.data.is) );
				isPreregistered = response.data.is;
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            console.error( JSON.stringify(error,null,2));
        });
        return isPreregistered;
    }
}
